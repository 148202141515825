Lyte.Component.register("crux-permission-denied-alert", {
_template:"<template tag-name=\"crux-permission-denied-alert\" class=\"crux-permission-denied-alert\"> <lyte-alert data-zcqa=\"crm_error_page\" lt-prop-buttons=\"{{cxPropButtons}}\" lt-prop-button-position=\"{{cxPropButtonPosition}}\" lt-prop-show=\"{{lbind(cxPropShow)}}\" lt-prop-top=\"{{cxPropTop}}\" lt-prop-wrapper-class=\"cxPermissionDeniedAlert\" lt-prop-yield=\"true\" lt-prop-show-close-button=\"false\" on-accept=\"{{method(&quot;onAcceptAlert&quot;)}}\" on-reject=\"{{method(&quot;onRejectAlert&quot;)}}\" on-show=\"{{method(&quot;onShowAlert&quot;)}}\" on-close=\"{{method(&quot;onCloseAlert&quot;)}}\"> <template is=\"registerYield\" yield-name=\"alert\"> <template is=\"if\" value=\"{{cxPropIcon}}\"><template case=\"true\"> <lyte-alert-header class=\"cxPermDenyWithIcon\"> <span class=\"cxPermissionDeniedIcon dIB vam\"></span> {{unescape(cxPropTitle)}} </lyte-alert-header> <lyte-alert-content class=\"cxPermDenyWithIcon\">{{unescape(cxPropReason)}}</lyte-alert-content> <template is=\"if\" value=\"{{cxPropFooterYield}}\"><template case=\"true\"> <lyte-alert-footer> <lyte-yield yield-name=\"footerYield\"></lyte-yield> </lyte-alert-footer> </template></template> </template><template case=\"false\"> <lyte-alert-header> {{unescape(cxPropTitle)}} </lyte-alert-header> <lyte-alert-content> {{unescape(cxPropReason)}}</lyte-alert-content> <template is=\"if\" value=\"{{cxPropFooterYield}}\"><template case=\"true\"> <lyte-alert-footer> <lyte-yield yield-name=\"footerYield\"></lyte-yield> </lyte-alert-footer> </template></template> </template></template> </template> </lyte-alert> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"text","position":[1,3]},{"type":"componentDynamic","position":[1]},{"type":"text","position":[3,0]},{"type":"componentDynamic","position":[3]},{"type":"attr","position":[5]},{"type":"if","position":[5],"cases":{"true":{"dynamicNodes":[{"type":"insertYield","position":[1,1]},{"type":"componentDynamic","position":[1]}]}},"default":{}}]},"false":{"dynamicNodes":[{"type":"text","position":[1,1]},{"type":"componentDynamic","position":[1]},{"type":"text","position":[3,1]},{"type":"componentDynamic","position":[3]},{"type":"attr","position":[5]},{"type":"if","position":[5],"cases":{"true":{"dynamicNodes":[{"type":"insertYield","position":[1,1]},{"type":"componentDynamic","position":[1]}]}},"default":{}}]}},"default":{}}]},{"type":"componentDynamic","position":[1]}],
_observedAttributes :["cxPropReason","cxPropTitle","cxPropShow","cxPropIcon","cxPropButtonText","cxPropButtons","cxPropButtonPosition","cxPropTop","cxPropFooterYield"], //NO I18N
	data : function(){
		return {
			cxPropReason : Lyte.attr("string"), //NO I18N
			cxPropTitle :  Lyte.attr("string"), //NO I18N
			cxPropShow : Lyte.attr("boolean"), //NO I18N
			cxPropIcon : Lyte.attr("boolean",{default : true}), //NO I18N
			cxPropButtonText : Lyte.attr("string",{default : _cruxUtils.getI18n("crm.button.ok")}), //NO I18N
			cxPropButtons : Lyte.attr("array"), //NO I18N
			cxPropButtonPosition : Lyte.attr("string",{default : "right"}), //NO I18N
			cxPropTop : Lyte.attr("string",{default : "0px"}), //NO I18N
			cxPropFooterYield : Lyte.attr("boolean" , {default : false}) //NO I18N
		}
	},
	init : function(){
		if(!this.data.cxPropButtons){
			this.setData("cxPropButtons" , [{"type":"accept","text": this.data.cxPropButtonText ,"appearance":"default"}]);//NO I18N
		}
	},
	methods : {
		onAcceptAlert : function(){
			this.setData("cxPropShow" , false); //NO I18N
			if(this.getMethods('onAccept')){ //NO I18N
				this.executeMethod('onAccept'); //NO I18N
		 	}
		},
		onShowAlert : function(){
			var reason = this.getData("cxPropReason"); //NO I18N
			var title = this.getData("cxPropTitle"); //NO I18N
			if(!reason){
				this.setData("cxPropReason",_cruxUtils.getI18n("crm.security.error")) //NO I18N
			}else{
				reason = reason.trim().replace(/\s+/g, " ");
				this.setData("cxPropReason",reason); //NO I18N
			}
			if(!title){
				this.setData("cxPropTitle",_cruxUtils.getI18n("crm.label.creator.noPermission")) //NO I18N
			}else{
				title = title.trim().replace(/\s+/g, " ");
				this.setData("cxPropTitle",title); //NO I18N
			}
			var button = $L(".cxPermissionDeniedAlert lyte-button")[0];
			button && button.setAttribute("data-zcqa","crm_permission_error");
			if(this.getMethods('onShow')){ //NO I18N
				this.executeMethod('onShow'); //NO I18N
		 	}
		 },
		 onCloseAlert : function(){
			if(this.getMethods('onClose')){ //NO I18N
				this.executeMethod('onClose'); //NO I18N
		 	}
		},
		onRejectAlert : function(){
			if(this.getMethods('onReject')){ //NO I18N
				this.executeMethod('onReject'); //NO I18N
		 	}
		}
	}

});
